module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/media/ssd/html/build/kouizine/packages/gatsby-theme-recipes/src/components/MarkdownPagesLayout.js","pages":"/media/ssd/html/build/kouizine/packages/gatsby-theme-recipes/src/components/MarkdownLayout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":510,"maxHeight":510,"linkImagesToOriginal":false,"withWebp":true,"quality":75}}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-recipes/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
