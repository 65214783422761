import theme from "@marinda/gatsby-theme-recipes/src/gatsby-plugin-theme-ui";

export default {
  ...theme,
  colors: {
    ...theme.colors,
    text: "#362101",
    title: "#6a0a00",
    link: "#a93226",
    background: "#fff",
    primary: "#c31d03",
    secondary: "#862E9C",
    accent: "#F4D3E5",
    muted: "#a52a2a",
    tags: {
      ...theme.colors.tags,
      text: "#fff",
      background: "#808b96",
      selected: "#2c3e50"
    }
  }
};
